<template>
  <section class="sidebarRecord">
    <div class="sidebarRecord__screen" @click="closeSidebar" v-if="recordId"></div>
    <div :class="`sidebarRecord__wrapper sidebarRecord__wrapper--${recordId ? 'open' : 'close'} sidebarRecord__wrapper--${mainFeature}`">
      <div class="sidebarRecord__header">
        <div class="sidebarRecord__contentArrows">
          <iconic
            :class="`sidebarRecord__icon sidebarRecord__icon--prev sidebarRecord__icon--${!showPrev ? 'hidde' : ''}`"
            name="arrow_right"
            @click.native="changeRecord(-1)"
          />
          <iconic
            :class="`sidebarRecord__icon sidebarRecord__icon--next sidebarRecord__icon--${!showNext ? 'hidde' : ''}`"
            name="arrow_right"
            @click.native="changeRecord(1)"
          />
        </div>
        <div class="sidebarRecord__contentActions">
          <iconic class="sidebarRecord__icon sidebarRecord__icon--history" name="history" />
          <iconic class="sidebarRecord__icon" name="close" @click.native="closeSidebar" />
        </div>
      </div>
      <div class="sidebarRecord__item">
        <FeatureButtons :record="record" />
        <ActionButtons :onCancel="() => {}" :onSuccess="() => {}" />
      </div>
      <div class="sidebarRecord__contentData">
        <div class="sidebarRecord__item">
          <p class="sidebarRecord__recordName">{{ record.name }}</p>
          <span class="sidebarRecord__recordCity" v-if="record.city">{{ record.city }}</span>
        </div>
        <span class="sidebarRecord__registerDate">{{ formatDate(record.date) }}</span>
        <div class="sidebarRecord__itemRow">
          <span class="sidebarRecord__detail">
            <iconic name="cake" />
            <span>{{ birthDay }}</span>
          </span>
          <span class="sidebarRecord__detail">{{ `${age} años` }}</span>
          <span class="sidebarRecord__detail">{{ hasExperience(record.hasWebcam) }}</span>
        </div>
        <template v-if="contactData.length">
          <div
            class="sidebarRecord__itemRow relative"
            v-for="contactMode in contactData"
            :key="contactMode.icon"
            @click="contactMode.func(contactMode.value, contactMode.icon)"
          >
            <iconic class="sidebarRecord__icon sidebarRecord__icon--contact" :name="contactMode.icon" />
            <span>{{ contactMode.value }}</span>
            <span class="sidebarRecord__tootip" v-if="keyCopied === contactMode.icon">Copiado</span>
          </div>
          <div class="sidebarRecord__contactContent">
            <iconic class="sidebarRecord__icon sidebarRecord__icon--contact" name="user_contact" />
            <div>
              <span>Contactar por</span>
              <span
                :class="['sidebarRecord__itemContact', modeName.mark && 'sidebarRecord__itemContact--mark']"
                v-for="(modeName, idx) in contactModes"
                :key="modeName.value"
              >
                {{ modeName.value }}
              </span>
            </div>
          </div>
        </template>
        <div class="sidebarRecord__contactContent">
          <iconic class="sidebarRecord__icon sidebarRecord__icon--contact" name="clock" />
          <span :class="['sidebarRecord__itemContact']" v-for="(time, idx) in timeAvailability" :key="time">{{ time }}</span>
        </div>
      </div>
      <ModelMessage :message="record.youMessage" />
      <div class="sidebarRecord__contentMedia" :key="resetMedia">
        <div class="sidebarRecord__contentImg" v-for="(photo, idx) in record.photos" :key="photo.name">
          <iconic class="sidebarRecord__icon sidebarRecord__icon--trash" name="trash" />
          <img class="sidebarRecord__img" :src="photo.link" :alt="photo.name" @click="showMediaInModal(idx)" />
        </div>
      </div>
      <div class="sidebarRecord__contactContent">
        <iconic class="sidebarRecord__icon sidebarRecord__icon--contact" name="plus" />
        <span>Agregar fotos</span>
      </div>
      <CommentsRecord :comments="record.comments" :recordId="record._id" />
    </div>
  </section>
</template>

<script>
export default {
  components: {
    ActionButtons: () => import("../mainRecords/ActionButtons.vue"),
    FeatureButtons: () => import("../mainRecords/FeatureButtons.vue"),
    ModelMessage: () => import("../mainRecords/ModelMessage.vue"),
    CommentsRecord: () => import("../mainRecords/CommentsRecord.vue"),
  },
  data() {
    return {
      dicModes: {
        phone: {
          name: "teléfono",
          icon: "phone",
          func: this.copyTextToClipboard,
        },
        whatsapp: {
          name: "whatsapp",
          icon: "whatsapp",
          func: this.openWhatsapp,
        },
        email: {
          name: "correo",
          icon: "card_email",
          func: this.copyTextToClipboard,
        },
      },
      resetMedia: 1,
      keyCopied: "",
      timeOutCopied: null,
    };
  },
  computed: {
    recordId() {
      return this.$store.state.records.recordDetailId;
    },
    records() {
      return this.$store.state.records.recordsInFilter;
    },
    recordIdx() {
      const recordId = this.recordId;
      const idx = recordId ? this.records.findIndex(({ _id }) => recordId === _id.toString()) : -1;

      return idx;
    },
    record() {
      return this.recordIdx !== -1 ? this.records[this.recordIdx] : {};
    },
    hasMore() {
      return this.$store.state.records.hasMore;
    },
    showPrev() {
      return this.recordIdx !== -1 && this.recordIdx > 0;
    },
    showNext() {
      return this.recordIdx !== -1 && this.recordIdx < this.records.length - 1;
    },
    queryString() {
      const { query, params } = this.$route;

      let queryString = `status=${params?.status || ""}&`;
      Object.entries(query).forEach(([key, val]) => (queryString += `${key}=${val}&`));

      return queryString;
    },
    mainFeature() {
      if (this.age && this.age < 18) {
        return "younger";
      }
      if (this.record.isSelected) {
        return "selected";
      }
      return this.features.find(({ selected }) => selected)?.key || "";
    },
    features() {
      const dicDates = {
        priority: { name: "Prioridad", value: "high" },
        satelite: { name: "Satélite", value: "satelite" },
        gender: { name: "Masculino", value: "male" },
      };

      return Object.entries(dicDates).map(([key, { name, value }]) => ({
        key,
        name,
        value,
        selected: this.record[key] === value,
      }));
    },
    age() {
      const birthDayDate = this.record.birthDayDate;
      if (!birthDayDate) {
        return "";
      }
      const birthDateObject = new Date(birthDayDate);

      if (isNaN(birthDateObject)) {
        return "Sin edad";
      }

      const currentDate = new Date();
      const timeDifferenceInMilliseconds = currentDate - birthDateObject;
      const ageInMilliseconds = new Date(timeDifferenceInMilliseconds);
      const age = Math.abs(ageInMilliseconds.getUTCFullYear() - 1970);

      return age;
    },
    birthDay() {
      const birthDayDate = this.record.birthDayDate;

      if (!birthDayDate) {
        return "";
      }
      return new Date(birthDayDate).toLocaleString("es-CO", { dateStyle: "long" });
    },
    contactData() {
      const dicModes = this.dicModes;

      return Object.entries(dicModes)
        .map(([key, val]) => {
          const valCompare = key === "whatsapp" ? "phone" : key;
          if (!this.record?.[valCompare]) {
            return null;
          }

          return {
            ...val,
            value: `${valCompare === "phone" && this.record.inputPostalCode ? `+${this.record.inputPostalCode} ` : ""}${this.record[valCompare]}`,
          };
        })
        .filter(Boolean);
    },
    contactModes() {
      const modes = (this.record?.contactMode || []).includes("all") ? ["phone", "whatsapp", "email"] : this.record?.contactMode || [];

      return modes.flatMap((mode, idx) => [
        { value: this.hasMarks(this.dicModes[mode].name, idx, modes.length), mark: true },
        { value: idx === modes.length - 1 ? "" : idx === modes.length - 2 ? " o " : " ", mark: false },
      ]);
    },
    timeAvailability() {
      const timeAvailability = this.record?.timeAvailability || [];
      const dicHoraies = {
        morning: "Mañana",
        late: "Tarde",
        night: "Noche",
        open: "Todos",
      };

      const idx = timeAvailability.findIndex((key) => key === "open");

      if (idx !== -1) {
        return ["Todos los horarios."];
      }
      return timeAvailability.map((key, idx) => `${idx !== 0 ? " - " : ""} ${dicHoraies[key]}`);
    },
  },
  methods: {
    closeSidebar() {
      this.$store.state.records.recordDetailId = null;
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return new Date(date).toLocaleString("es-CO", { dateStyle: "long", timeStyle: "short" });
    },
    hasExperience(val) {
      return `${val && val === "yes" ? "Con" : "Sin"} experiencia`;
    },
    hasMarks(str, idx, length) {
      const lastIdx = length - 1;
      return (str += idx === lastIdx ? "." : idx !== lastIdx - 1 ? "," : "");
    },
    showMediaInModal(initialSlide) {
      this.$store.state.records.imagesToShow = {
        images: this.record.photos,
        initialSlide,
        recordId: this.record._id,
      };
    },
    async changeRecord(num) {
      const idx = this.recordIdx === -1 || !this.records.length ? -1 : this.recordIdx + num;

      if (idx === -1) {
        return;
      }

      const requirePagination = (this.records.length > 2 ? this.records.length - 2 <= idx : this.records.length - 1 <= idx) && this.hasMore;

      if (requirePagination) {
        await this.$store.dispatch("records/getMoreRecords", this.queryString);
      }
      if (this.records.length <= idx) {
        return;
      }

      const recordDetailId = this.records[idx]._id;
      this.$store.state.records.recordDetailId = recordDetailId;
      this.resetMedia++;
    },
    copyTextToClipboard(text, showCopied) {
      if (!text) {
        return;
      }

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log("Texto copiado al portapapeles.");
          })
          .catch((error) => {
            console.error("Error al copiar al portapapeles:", error);
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
          console.log("Texto copiado al portapapeles.");

          this.keyCopied = showCopied;

          clearTimeout(this.timeOutCopied);
          this.timeOutCopied = setTimeout(() => {
            this.keyCopied = "";
          }, 1000);
        } catch (error) {
          console.error("Error al copiar al portapapeles:", error);
        } finally {
          document.body.removeChild(textArea);
        }
      }
    },
    openWhatsapp(number) {
      if (!number) {
        return;
      }
      const cleanNumber = number.replace(/[+ ]/g, "").replace(/^0+/, "");
      const whatsappURL = `https://wa.me/${cleanNumber}`;

      window.open(whatsappURL, "_blank");
    },
  },
};
</script>

<style lang="scss">
.sidebarRecord {
  position: absolute;
  &__screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  &__wrapper {
    position: fixed;
    top: 49.5px;
    right: 0;
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    height: calc(100% - 50px);
    max-width: 462px;
    padding: 15px;
    gap: 15px;
    background: #f9f8fd;
    box-shadow: -5px 0px 16px 0px #00000024;
    z-index: 6;
    transition: 0.3s ease-in-out;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &--open {
      right: 0;
    }
    &--close {
      right: -462px;
    }
    &--younger {
      background: #fbd7d7;
    }
    &--priority {
      background: #f1fff1;
    }
    &--satelite {
      background: #fde8d0;
    }
    &--gender {
      background: #f1faff;
    }
  }
  &__contentData {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 8px;
  }
  &__header {
    @include Flex(row, space-between);
    width: 100%;
  }
  &__item,
  &__contentActions,
  &__contentArrows {
    @include Flex(row);
  }
  &__icon {
    padding: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &--prev {
      transform: rotateY(180deg);
    }
    &--history {
      color: #5b595c;
    }
    &--contact {
      padding: 0 2px;
      color: #5b5957;
    }
    &--trash {
      @include Flex(row);
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 8px;
      font-size: 14px;
      color: #ffffff;
      background: #000;
      border-radius: 50%;
    }
    &--hidde {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__item {
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }
  &__contentIcon {
    @include Flex(row);
    height: 27px;
    width: 27px;
    font-size: 15px;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
  }
  &__recordName {
    font-weight: 600;
    white-space: wrap;
  }
  &__recordCity {
    @include Flex(row);
    height: 23px;
    padding: 0 10px;
    text-transform: capitalize;
    font-size: 14px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 17px;
  }
  &__registerDate {
    font-size: 12px;
  }
  &__itemRow {
    @include Flex(row, flex-start);
    flex-wrap: wrap;
    gap: 8px;
  }
  &__contactContent {
    @include Flex(row, flex-start);
    flex-wrap: nowrap;
    gap: 8px;
  }
  &__detail {
    @include Flex(row);
    height: 20px;
    gap: 8px;
    padding: 0 10px;
    font-size: 14px;
    color: white;
    background: #767676;
    border-radius: 50px;
  }
  &__itemContact {
    &--mark {
      text-decoration: underline;
    }
  }
  &__contentMedia {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 15px;
  }
  &__contentImg {
    position: relative;
  }
  &__img {
    width: 100%;
    height: 100%;
    max-height: 252px;
    border-radius: 10px;
    box-shadow: 0px 0px 8.929872512817383px 0px #0000004d;
    object-fit: cover;
    object-position: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  &__tootip {
    position: absolute;
    left: 15%;
    bottom: 135%;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    background-color: black;
    box-shadow: $dshadow;
    border-radius: 6px;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
</style>
